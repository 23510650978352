<template>
  <section>
    <BrunaForm
      ref="demandDetailRef"
      :fields="this.demand_detail.fields"
      :card="this.demand_detail.card"
      @change="demandDetailChange"
    ></BrunaForm>

    <BrunaForm
      ref="orderRef"
      :fields="this.order.fields"
      :card="this.order.card"
      @change="orderChange"
    ></BrunaForm>

    <BrunaForm
      ref="skuRef"
      :fields="this.sku.fields"
      :card="this.sku.card"
      @change="skuChange"
    ></BrunaForm>

    <BrunaForm
      ref="priceRef"
      :fields="this.price.fields"
      :card="this.price.card"
      @change="priceChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';

import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  created()
  {
    this.loadLastPrediction()
    this.loadSkus()
    this.loadCountries()

  },


  data()
  {

    let distribucion_ton_mensual = []

    for (let mes = 1; mes <= 12; mes++)
    {
      distribucion_ton_mensual.push(

        {
          label: "Mes " + mes,
          type: "input",
          id: 'mes' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1
          },
          value: null
        },
      );
    }


    return {

      selectedPrediction: null,
      predictions: [],


      demand_detail: {
        card: {
          title: "Demand Detail",
          subtitle: "The identification of the type of demand (Spot or Program) or type of Contract determines the calculation of the FOBChile price and the fields to be displayed.",
          tooltip: "SPOT-type sales have prices automatically calculated using UB+Tier per customer."
        },
        fields: [

          {
            label: "Office",
            type: "select",
            id: 'office',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una oficina" },
              { value: "usa", text: "USA" },
              { value: "canada", text: "Canada" },
              { value: "mexico", text: "Mexico" },
              { value: "japon", text: "Japon" },
              { value: "brasil", text: "Brasil" },
              { value: "chile", text: "Chile" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Program Type",
            type: "select",
            id: 'program_type',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona un tipo" },
              { value: "fresh", text: "Fresh" },
              { value: "frozen", text: "Frozen" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },



      order: {

        card: {
          title: "Order",
          subtitle: "The characterization of the order allows calculating the FOBChile price that is used in the calculation of the RMP.",
          tooltip: ""
        },


        fields: [

          {
            label: "Status",
            type: "select",
            id: 'status',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona un estado" },
              { value: "sold", text: "Sold" },
              { value: "unsold", text: "Unsold" },
              { value: "minreq", text: "MinReq" }
            ],
            value: null
          },


          {
            label: "Start Date",
            type: "datepicker",
            id: 'start_date',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "End Date",
            type: "datepicker",
            id: 'end_date',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


        ]
      },

      sku: {

        card: {
          title: "SKU",
          subtitle: "Add all SKUs associated with this sale. If the price is Program, the price to enter is CFR. If the price is SPOT, it is calculated automatically from the Value of the client's UB+Tier.",
          tooltip: ""
        },

        fields: [

          {
            label: "Product Code",
            type: "select",
            id: 'product_code',
            rules: 'required',
            tooltip: null,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },

            options: [{ value: null, text: "Selecciona una opcion" }],
            value: null

          },

          {
            label: "Product Name",
            type: "input",
            id: 'product_name',
            rules: 'required',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },

          {
            label: "Representative Trim D Equivalent",
            type: "input",
            id: 'representative_trim_d_equivalent',
            rules: 'required',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },

          {
            label: "Quality",
            type: "input",
            id: 'quality',
            rules: 'required',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },

          {
            label: "Family",
            type: "input",
            id: 'family',
            rules: 'required',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },

          {
            label: "RC Ajuste Sobrepeso",
            type: "input",
            id: 'r_c_ajuste_sobrepeso',
            rules: 'required',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "Net Quantity",
            type: "input",
            id: 'net_quantity',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "Unit",
            type: "select",
            id: 'unit',
            rules: 'required',
            tooltip: null,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "kg", text: "Kg" },
              { value: "lb", text: "Lb" }
            ],
            value: null
          },


          {
            label: "Overweight",
            type: "select",
            id: 'overweight',
            rules: 'required',
            tooltip: null,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 0.00, text: "0%" },
              { value: 0.01, text: "1%" },
              { value: 0.02, text: "2%" },
              { value: 0.03, text: "3%" },
              { value: 0.04, text: "4%" },
              { value: 0.05, text: "5%" },
              { value: 0.06, text: "6%" },
              { value: 0.07, text: "7%" },
              { value: 0.08, text: "8%" },
              { value: 0.09, text: "9%" },
              { value: 0.10, text: "10%" },
              { value: 0.11, text: "11%" },
              { value: 0.12, text: "12%" },
              { value: 0.13, text: "13%" },
              { value: 0.14, text: "14%" },
              { value: 0.15, text: "15%" },
              { value: 0.16, text: "16%" },
              { value: 0.17, text: "17%" },
              { value: 0.18, text: "18%" },
              { value: 0.19, text: "19%" },
              { value: 0.20, text: "20%" },
            ],
            value: null
          },

          {
            label: "Kgs with Overweight",
            type: "input",
            id: 'tons_overweight',
            rules: 'required|numeric2',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "WFE KG",
            type: "input",
            id: 'wfe_kg',
            rules: 'required|numeric2',
            tooltip: null,
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "FOB OFFICE USD/UNIT",
            type: "input",
            id: 'fob_office_usd_unit',
            rules: 'numeric2',
            tooltip: null,
            disabled: false,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "Sale Price",
            type: "input",
            id: 'sale_price',
            rules: 'required|numeric2',
            tooltip: 'For Programs the price is CFR Destination Port',
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },

          {
            label: "Currency Sale Price",
            type: "select",
            id: 'currency_sale_price',
            rules: 'required',
            tooltip: null,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "usd", text: "USD" },
              { value: "eu", text: "EU" },
              { value: "clp", text: "CLP" }
            ],
            value: null
          },


          {
            label: "USD Exchange Rate",
            type: "input",
            id: 'usd_exchange_rate',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "Shipping Costs",
            type: "input",
            id: 'shipping_costs',
            rules: 'required|numeric2',
            tooltip: 'Program: Freight; Spot: freight + commissions + expenses',
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "FOB Chile USD/Kg",
            type: "input",
            id: 'fob_chile_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          {
            label: "RMP USD/Kg",
            type: "input",
            id: 'rmp_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: null
          },


          ...distribucion_ton_mensual


        ]



      },


      price: {

        card: {
          title: "Forecast Total",
          subtitle: "",
          tooltip: ""

        },

        fields: [

          {
            label: "Total Forecast Overweight KG",
            type: "input",
            id: 'total_contract_overweight_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Forecast WFE KG",
            type: "input",
            id: 'total_contract_wfe_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Shipping Cost USD",
            type: "input",
            id: 'total_shipping_cost_usd',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Forecast Value USD FOB CHILE",
            type: "input",
            id: 'total_contract_value_usd_fob_chile',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "FOB Chile Weighted USD/KG",
            type: "input",
            id: 'fob_chile_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "RMP Weighted USD/KG",
            type: "input",
            id: 'rmp_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },






        ]
      },





    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    CosechaUrgente,
    Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
    BrunaFormRepeat
  },
  methods: {

    loadLastPrediction()
    {

      useApiServices.getLastPrediction(this).then((response) =>
      {
        let values = response.data.prediction_values

        let out = values.map(value =>
        {
          value.meta_data.parsed_date = new Date(value.meta_data.fecha)
          return value.meta_data;
        })

        let ordenado = out.sort(function (a, b)
        {
          return new Date(a.parsed_date) - new Date(b.parsed_date);
        });

        this.predictions = ordenado

      })
        .catch((error) =>
        {
          console.log(error);

        });


    },



    loadCountries()
    {
      useApiServices.getCountries(this).then((response) =>
      {
        let countries_options = [
          { value: null, text: "Selecciona una opcion" }
        ]

        response.data.forEach(item =>
        {

          item.origen = 'cl'
          item.np_sap = "cl"

          countries_options.push({ value: item, text: item.name })

        });

        //this.order.fields[5].options = countries_options;

      })
        .catch((error) =>
        {
          console.log(error);

        });

    },

    loadSkus()
    {

      useApiServices.getSkus(this).then((response) =>
      {
        let sku_options = [
          { value: null, text: "Selecciona un codigo" }
        ]

        response.data.forEach(item =>
        {

          sku_options.push({
            value: {
              id: item.id,
              codigo: item.meta_data['1_codigo'],
              r_c_ajuste_sobrepeso: item.meta_data['24_r_c_ajuste_sobrepeso'],
              product_name: item.meta_data['8_descripcion'],
              representative_trim_d_equivalent: item.meta_data['38_calibre_trim_d_eq'],
              quality: item.meta_data['10_calidad'],
              family: item.meta_data['6_familia'],
              tons_overweight: 0,
              c_dist: item.meta_data['2_c_dist'],
              c_proceso_total: item.meta_data['4_c_proceso_total'],
              c_emp: item.meta_data['3_c_emp'],
              aporte_neto_total_wfe_us_kgwfe: item.meta_data['37_aporte_neto_total_wfe_us_kgwfe'],
            },
            text: item.meta_data['1_codigo']
          })

        });

        this.findFieldByName(this.sku.fields, 'product_code').options = sku_options

      })
        .catch((error) =>
        {
          console.log(error);

        });

    },

    findFieldByName(fields, name)
    {
      return fields.find(field => field.id == name)
    },

    formatData()
    {
      return {
        demand_detail: this.demand_detail.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        order: this.order.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        sku: this.sku.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        price: this.price.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),


      }
    },



    save()
    {
      let self = this;

      let data = self.formatData()

      console.log({ data })

      Promise.all([
        this.$refs.demandDetailRef.validate(),
        this.$refs.orderRef.validate(),
        this.$refs.skuRef.validate(),
        this.$refs.priceRef.validate(),


      ]).then(([demandDetailValid, orderValid, skuValid, priceValid]) =>
      {

        if (demandDetailValid && orderValid && skuValid && priceValid)
        {

          useApiServices.postForecastCreate(data, this)
            .then((response) =>
            {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `El contrato se ha creado exitosamente.`,
                },
              });


            })
            .catch((error) =>
            {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: `Ha ocurrido un error al guardar el contrato</b>.`,
                },
              });

            });

        }

      })

    },

    priceChange(field)
    {
    },


    findPrediction(date)
    {
      for (let i = 0; i < this.predictions.length - 1; i++)
      {
        let current = this.predictions[i];
        let next = this.predictions[i + 1];

        if (current.parsed_date <= date && date <= next.parsed_date)
        {
          let rmp_ub_2_3 = (current.rmp_ub_2_3 + next.rmp_ub_2_3) / 2
          let rmp_ub_3_4 = (current.rmp_ub_3_4 + next.rmp_ub_3_4) / 2
          let rmp_ub_4_5 = (current.rmp_ub_4_5 + next.rmp_ub_4_5) / 2

          let ub_1_2 = (current.ub_1_2 + next.ub_1_2) / 2
          let ub_2_3 = (current.ub_2_3 + next.ub_2_3) / 2
          let ub_3_4 = (current.ub_3_4 + next.ub_3_4) / 2
          let ub_4_5 = (current.ub_4_5 + next.ub_4_5) / 2




          return { rmp_ub_2_3, rmp_ub_3_4, rmp_ub_4_5, ub_1_2, ub_2_3, ub_3_4, ub_4_5 }
        }


      }

      throw new Error("Intervalo de predicciones no encontrado");

    },


    orderChange(field)
    {
      console.log("orderChange", field);

      if (field.id == "start_date")
      {

        this.selectedPrediction = this.findPrediction(new Date(field.value))

      }

      this.calculate();

    },


    skuChange(field)
    {
      console.log("skuChange", { field });

      let exchange_rate_eu_usd = 1.1;
      let exchange_rate_clp_usd = 850;
      let exchange_rate_usd_usd = 1;


      if (field.id == "currency_sale_price")
      {

        if (field.value == "eu")
        {
          this.findFieldByName(this.sku.fields, 'usd_exchange_rate').value = exchange_rate_eu_usd
        }
        else if (field.value == "clp")
        {
          this.findFieldByName(this.sku.fields, 'usd_exchange_rate').value = exchange_rate_clp_usd
        }
        else if (field.value == "usd")
        {
          this.findFieldByName(this.sku.fields, 'usd_exchange_rate').value = exchange_rate_usd_usd
        }
        else { }


      }


      if (field.id == "product_code")
      {
        let representative_trim_d_equivalent = field.value.representative_trim_d_equivalent

        this.findFieldByName(this.sku.fields, 'product_name').value = field.value.product_name
        this.findFieldByName(this.sku.fields, 'representative_trim_d_equivalent').value = representative_trim_d_equivalent
        this.findFieldByName(this.sku.fields, 'quality').value = field.value.quality
        this.findFieldByName(this.sku.fields, 'family').value = field.value.family
        this.findFieldByName(this.sku.fields, 'r_c_ajuste_sobrepeso').value = field.value.r_c_ajuste_sobrepeso

        let ub_price = 0

        if (representative_trim_d_equivalent == "0-1 LB")
        {
          ub_price = this.selectedPrediction.ub_2_3 - 0.4;
        }
        else if (representative_trim_d_equivalent == "1-2 LB")
        {
          ub_price = this.selectedPrediction.ub_1_2
        }
        else if (representative_trim_d_equivalent == "2-3 LB")
        {
          ub_price = this.selectedPrediction.ub_2_3

        }
        else if (representative_trim_d_equivalent == "3-4 LB")
        {
          ub_price = this.selectedPrediction.ub_3_4

        }
        else if (representative_trim_d_equivalent == "4-5 LB")
        {
          ub_price = this.selectedPrediction.ub_4_5

        }
        else if (representative_trim_d_equivalent == "5-6 LB")
        {
          ub_price = this.selectedPrediction.ub_4_5

        }
        else if (representative_trim_d_equivalent == "6-UP")
        {
          ub_price = this.selectedPrediction.ub_4_5

        }
        else if (representative_trim_d_equivalent == "5-UP LB")
        {
          ub_price = this.selectedPrediction.ub_4_5

        }
        else if (representative_trim_d_equivalent == "N/A")
        {
          ub_price = 0
        }
        else
        {
          ub_price = 0
        }


        //let reward_over_ub_usd_kg = parseFloat(this.findFieldByName(this.buyer.fields, 'reward_over_ub_usd_kg').value)

        //console.log({ ub_price, reward_over_ub_usd_kg })

        this.findFieldByName(this.sku.fields, 'sale_price').value = ub_price
        //this.findFieldByName(this.sku.fields, 'sale_price').value = ub_price + reward_over_ub_usd_kg



      }


      this.calculate();

    },

    calculate()
    {
      let total_contract_overweight_kg = 0
      let total_contract_wfe_kg = 0
      let total_shipping_cost_usd = 0
      let total_contract_value_usd_fob_chile = 0
      let rmp_weighted_usd_kg = 0

      let total_net_quantity = 0


      let exchange_rate_eu_usd = 1.1;
      let exchange_rate_clp_usd = 850;
      let exchange_rate_usd_usd = 1;

      let months = 12

      try
      {
        let start_date = this.findFieldByName(this.order.fields, 'start_date').value
        let end_date = this.findFieldByName(this.order.fields, 'end_date').value

        console.log({ start_date, end_date })

        let start_date_parsed = new Date(start_date)
        let end_date_parsed = new Date(end_date)

        console.log({ start_date_parsed, end_date_parsed })

        months = end_date_parsed.getMonth() - start_date_parsed.getMonth() + (12 * (end_date_parsed.getFullYear() - start_date_parsed.getFullYear()))

        console.log({ months })

        if (months <= 0)
        {
          months = 12
        }


      }
      catch (error)
      {
        console.log(error)
      }




      try
      {


        let selectedSku = this.findFieldByName(this.sku.fields, 'product_code').value

        let net_quantity_kgs = parseFloat(this.findFieldByName(this.sku.fields, 'net_quantity').value)
        let unit = this.findFieldByName(this.sku.fields, 'unit').value
        let overweight = parseFloat(this.findFieldByName(this.sku.fields, 'overweight').value)


        for (let mes = 1; mes <= 12; mes++)
        {
          if (mes <= months)
          {
            this.findFieldByName(this.sku.fields, 'mes' + mes).value = net_quantity_kgs / months

          } else
          {

            this.findFieldByName(this.sku.fields, 'mes' + mes).value = 0
          }

        }


        if (unit == "lb")
        {
          net_quantity_kgs = net_quantity_kgs * 0.4535
        }

        let tons_overweight = net_quantity_kgs * (1 + overweight)
        let wfe_kg = net_quantity_kgs / selectedSku.r_c_ajuste_sobrepeso

        this.findFieldByName(this.sku.fields, 'tons_overweight').value = tons_overweight
        this.findFieldByName(this.sku.fields, 'wfe_kg').value = wfe_kg



        let currency_sale_price = this.findFieldByName(this.sku.fields, 'currency_sale_price').value
        let sale_price = parseFloat(this.findFieldByName(this.sku.fields, 'sale_price').value)
        let sale_price_usd = 0


        if (currency_sale_price == 'eu')
        {
          sale_price_usd = (sale_price * exchange_rate_eu_usd)
        }
        else if (currency_sale_price == 'usd')
        {
          sale_price_usd = (sale_price * exchange_rate_usd_usd)
        }
        else if (currency_sale_price == 'clp')
        {
          sale_price_usd = (sale_price * exchange_rate_clp_usd)
        }
        else
        {

        }

        //let shipping_costs = this.selectedFreight.meta_data.congelado_usd_kg_net * tons_overweight;
        let shipping_costs = 0

        let fob_chile_usd_kg = ((net_quantity_kgs * sale_price_usd) - shipping_costs) / net_quantity_kgs;
        let rmp_usd_kg = ((fob_chile_usd_kg - selectedSku.c_dist - selectedSku.c_proceso_total - selectedSku.c_emp) * selectedSku.r_c_ajuste_sobrepeso) + selectedSku.aporte_neto_total_wfe_us_kgwfe

        this.findFieldByName(this.sku.fields, 'shipping_costs').value = shipping_costs
        this.findFieldByName(this.sku.fields, 'fob_chile_usd_kg').value = fob_chile_usd_kg
        this.findFieldByName(this.sku.fields, 'rmp_usd_kg').value = rmp_usd_kg


        total_contract_overweight_kg += tons_overweight
        total_contract_wfe_kg += wfe_kg
        total_shipping_cost_usd += shipping_costs

        total_contract_value_usd_fob_chile += (net_quantity_kgs * fob_chile_usd_kg)

        rmp_weighted_usd_kg += (net_quantity_kgs * rmp_usd_kg)

        total_net_quantity += net_quantity_kgs




        this.findFieldByName(this.price.fields, 'total_contract_overweight_kg').value = total_contract_overweight_kg
        this.findFieldByName(this.price.fields, 'total_contract_wfe_kg').value = total_contract_wfe_kg
        this.findFieldByName(this.price.fields, 'total_shipping_cost_usd').value = total_shipping_cost_usd
        this.findFieldByName(this.price.fields, 'total_contract_value_usd_fob_chile').value = total_contract_value_usd_fob_chile
        this.findFieldByName(this.price.fields, 'fob_chile_weighted_usd_kg').value = total_contract_value_usd_fob_chile / total_net_quantity
        this.findFieldByName(this.price.fields, 'rmp_weighted_usd_kg').value = rmp_weighted_usd_kg / total_net_quantity



      }
      catch (error)
      {
        console.log(error)
      }



    },



    demandDetailChange(field)
    {
    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>